@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/effect-fade';

@import 'glightbox/dist/css/glightbox.min.css';
@import "@fancyapps/ui/dist/fancybox/fancybox.css";

[x-cloak] { display: none !important; }

.uniform__potty {
    position: absolute;
    left: -9999px;
}